import React, { useState } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import * as auth from "../../store/ducks/auth.duck";
import { resetPassword } from "../../crud/auth.crud";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { validateAlterarSenha } from "./AuthUtils";
import queryString from 'query-string';
import Swal from "sweetalert2";

function AlterarSenha(props) {
	const [loading, setLoading] = useState(false);

	const submitEsqueceuSenha = (values, setStatus) => {
		setLoading(true);

		let { token, email } = queryString.parse(props.location.search);

		resetPassword({ ...values, token, email }).then(async () => {
			setLoading(false);

			await Swal.fire({
				icon: 'success',
				title: 'Tudo certo!',
				text: 'Sua senha foi redefinida com sucesso.',
				showConfirmButton: false,
				timer: 1500
			});

			window.location.href = '/';
		}).catch((err) => {
			setLoading(false);

			let error = err?.response?.data?.errors?.length > 0
				? err?.response?.data?.errors[0]
				: null;

			if (!error) {
				return Swal.fire('Ops', 'Ocorreu um erro ao tentar alterar sua senha. Por favor, entre em contato com o suporte.', 'error');
			}

			setStatus(error);
		});
	};

	return (
		<>
			<div className="d-flex justify-content-center">
				<Container fluid className="formLogin">
					<Formik initialValues={{ password: "", password_confirmation: "" }} validate={values => validateAlterarSenha(values)} onSubmit={(values, { setStatus, setSubmitting }) => submitEsqueceuSenha(values, setStatus, setSubmitting)}>
						{({ values, status, errors, touched, handleChange, handleBlur, handleSubmit }) => (
							<form noValidate={true} autoComplete="off" className="kt-form" onSubmit={handleSubmit}>
								{status &&
									<div role="alert" className="alert alert-danger">
										<div className="alert-text">{status}</div>
									</div>
								}

								<div className="d-flex flex-column justify-content-center">
									<center>
										<h3 style={{ color: '#000000' }}>Alterar minha senha</h3>
										<p style={{ color: '#000000' }}>Para recuperar seu acesso, digite a nova senha.</p>
									</center>
								</div>

								<Row>
									<Col sm={12}>
										<TextField type="password" name="password" label="Senha *" margin="normal" variant="outlined"
											onBlur={handleBlur} onChange={handleChange} helperText={touched.password && errors.password} error={Boolean(touched.password && errors.password)}
										/>
									</Col>

									<Col sm={12}>
										<TextField type="password" name="password_confirmation" label="Confirmar senha *" margin="normal" variant="outlined"
											onBlur={handleBlur} onChange={handleChange} helperText={touched.password_confirmation && errors.password_confirmation} error={Boolean(touched.password_confirmation && errors.password_confirmation)}
										/>
									</Col>
								</Row>

								<Row>
									<Col className="d-flex justify-content-around" sm={12}>
										<button type="submit" className="botaoEsqueceuSenha" disabled={loading} >
											{loading ? <i className="fas fa-spinner fa-spin"></i> : "Alterar senha"}
										</button>
									</Col>
								</Row>

								<Row>
									<Col className="d-flex justify-content-around mt-3" sm={12}>
										<p style={{ color: '#000000' }}>Lembrou a senha? <Link style={{ color: '#000000' }} to="/auth/login"><b>Faça login.</b></Link></p>
									</Col>
								</Row>
							</form>
						)}
					</Formik>
				</Container>
			</div>
		</>
	);
}

export default connect(null, auth.actions)(AlterarSenha)
