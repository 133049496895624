export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Painel",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Crianças",
				root: true,
				icon: "fas fa-hospital-user",
				page: "crianca",
				bullet: "dot"
			},
			{
				title: "Avaliação",
				root: true,
				icon: "fas fa-clipboard-list",
				page: "avaliacao",
				bullet: "dot"
			},
			{
				title: "Plano Terapêutico",
				root: true,
				icon: "fas fa-clipboard-check",
				page: "plano",
				bullet: "dot"
			},
      { section: "Relatórios" },
			{
				title: "Desenvolvimento",
				root: true,
				icon: "fas fa-chart-pie",
				bullet: "line",
				submenu: [
					{
						title: "Evolução",
						bullet: "line",
						submenu: [
							{
								title: "Tabela",
								bullet: "dot",
								page: "relatorio/evolucao"
							},
							{
								title: "Gráfico",
								bullet: "dot",
								page: "relatorio/evolucao-dominio"
							},
						]
					},
					{
						title: "Histórico",
						bullet: "dot",
						page: "relatorio/avaliacoes"
					},
					{
						title: "Painel",
						bullet: "dot",
						page: "relatorio/painel"
					}
				]
			},
			{
				title: "Acompanhamento",
				root: true,
				icon: "fas fa-chart-pie",
				bullet: "line",
				submenu: [
					{
						title: "Progresso",
						bullet: "dot",
						page: "relatorio/progresso"
					},
					{
						title: "Sucesso",
						bullet: "line",
						submenu: [
							{
								title: "Competência",
								bullet: "dot",
								page: "relatorio/sucesso-competencia"
							},
							{
								title: "Domínio",
								bullet: "dot",
								page: "relatorio/sucesso-dominio"
							}
						]
					}
				]
			},
      { section: "Organização" },
			{
				title: "Profissionais",
				root: true,
				icon: "fas fa-user-md",
				page: "profissional",
				bullet: "dot",
			},
			{
				title: "Família",
				root: true,
				icon: "fas fa-users",
				page: "usuario",
				bullet: "dot",
			},
			{
				title: "Meu Plano",
				root: true,
				icon: "fas fa-wallet",
				page: "meu-plano",
				bullet: "dot"
			},
		]
	}
};
