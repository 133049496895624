import React, { useState } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import * as auth from "../../store/ducks/auth.duck";
import { forgotPassword } from "../../crud/auth.crud";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { validateEsqueceuSenha } from "./AuthUtils";
import Swal from "sweetalert2";

function EsqueceuSenha(props) {
	const [loading, setLoading] = useState(false);

	const submitEsqueceuSenha = (values, setStatus) => {
		setLoading(true);

		forgotPassword(values.email).then(() => {
			setLoading(false);

			return Swal.fire('Sucesso', 'E-mail de recuperação enviado com sucesso!', 'success');
		}).catch((err) => {
			setLoading(false);

			let error = err?.response?.data?.errors?.length > 0
				? err?.response?.data?.errors[0]
				: null;

			if (!error) {
				return Swal.fire('Ops', 'Ocorreu um erro ao tentar enviar seu email. Por favor, entre em contato com o suporte.', 'error');
			}

			setStatus(error)
		});
	};

	return (
		<>
			<div className="d-flex justify-content-center">
				<Container fluid className="formLogin">
					<Formik initialValues={{ email: "" }} validate={values => validateEsqueceuSenha(values)} onSubmit={(values, { setStatus, setSubmitting }) => submitEsqueceuSenha(values, setStatus, setSubmitting)}>
						{({ values, status, errors, touched, handleChange, handleBlur, handleSubmit }) => (
							<form noValidate={true} autoComplete="off" className="kt-form" onSubmit={handleSubmit}>
								{status &&
									<div role="alert" className="alert alert-danger">
										<div className="alert-text">{status}</div>
									</div>
								}

								<div className="d-flex flex-column justify-content-center">
									<center>
										<h3 style={{ color: '#000000' }}>Esqueci minha senha</h3>
										<p style={{ color: '#000000' }}>Insira seu email e iremos enviar um link para realizar a troca de sua senha.</p>
									</center>
								</div>

								<Row>
									<Col sm={12}>
										<TextField type="email" label="Seu email" margin="normal" name="email" onBlur={handleBlur} onChange={handleChange} variant="outlined"
											value={values.email} helperText={touched.email && errors.email} error={Boolean(touched.email && errors.email)} />
									</Col>
								</Row>

								<Row>
									<Col className="d-flex justify-content-around" sm={12}>
										<button type="submit" className="botaoEsqueceuSenha" disabled={loading} >
											{loading ? <i className="fas fa-spinner fa-spin"></i> : "Enviar email"}
										</button>
									</Col>
								</Row>

								<Row>
									<Col className="d-flex justify-content-around mt-3" sm={12}>
										<p style={{ color: '#000000' }}>Lembrou a senha? <Link style={{ color: '#000000' }} to="/auth/login"><b>Faça login.</b></Link></p>
									</Col>
								</Row>
							</form>
						)}
					</Formik>
				</Container>
			</div>
		</>
	);
}

export default connect(null, auth.actions)(EsqueceuSenha)
