import React, { Component } from 'react';
import { MenuItem, OutlinedInput, InputLabel, FormControl, Select, FormHelperText } from "@material-ui/core";

export default class BetterSelect extends Component {
    render() {
        return (
            <FormControl variant="outlined" className="MuiFormControl-marginNormal" error={this.props.error ? this.props.error : false}>
                <InputLabel shrink={this.props.shrinkInputLabel}>{this.props.label}</InputLabel>

                <Select native={this.props.native} value={this.props.value} name={this.props.name ? this.props.name : ""} disabled={this.props.disabled}
                    onBlur={this.props.onBlur ? this.props.onBlur : null} onChange={this.props.onChange}
                    input={<OutlinedInput name={this.props.label} labelWidth={this.props.labelWidth} />}>

                    {this.props.blankOption ?
                        (this.props.native ? <option value="" /> : <MenuItem value="">---</MenuItem>)
                        : null
                    }

                    {this.props.children}
                </Select>

                {this.props.helperText ?
                    <FormHelperText>{this.props.helperText}</FormHelperText>
                    : null
                }
            </FormControl>
        );
    }
}