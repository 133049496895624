import { createFormikValidation, regexCPF, regexEmail, validaCpf } from "../../utils/Utils";
import moment from "moment";

export const validateFormCadastro = (values) => {
	let formMap = new Map();

	formMap.set('email', 'email');
	formMap.set('password', 'senha');
	formMap.set('fam_nome', 'nome');
	formMap.set('fam_cpf', 'cpf');
	formMap.set('fam_celular', 'celular');
	formMap.set('fam_sexo', 'sexo');
	formMap.set('fam_data_nascimento', 'data de nascimento');
	formMap.set('fam_cep', 'cep');
	formMap.set('fam_endereco', 'endereco');
	formMap.set('fam_numero', 'numero');
	formMap.set('fam_bairro', 'bairro');
	formMap.set('fam_estado', 'estado');
	formMap.set('fam_cidade', 'cidade');

	let errors = createFormikValidation(formMap, values);

	if (!regexEmail.test(values.email)) {
		errors.email = "Você precisa informar um email válido.";
	}

	if (!regexCPF.test(values.fam_cpf)) {
		errors.fam_cpf = "Você precisa informar um cpf válido.";
	}

	return errors;
};

export const validateLogin = (values) => {
	let errors = {};

	if (!values.email) {
		errors.email = "O email é uma informação obrigatória.";
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
		errors.email = "Você precisa informar um email válido.";
	}

	if (!values.password) {
		errors.password = "A senha é uma informação obrigatória.";
	}

	return errors;
};

export const validateEsqueceuSenha = (values) => {
	let errors = {};

	if (!values.email) {
		errors.email = "O email é uma informação obrigatória.";
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
		errors.email = "Você precisa informar um email válido.";
	}

	return errors;
};

export const validateAlterarSenha = (values) => {
	let errors = {};

	if (!values.password) {
		errors.password = "A senha é uma informação obrigatória.";
	} else if (values.password.length < 6) {
		errors.password = "Sua nova senha precisa ter no mínimo 6 caracteres.";
	}

	if (!values.password_confirmation) {
		errors.password_confirmation = "A confirmação de senha é uma informação obrigatória.";
	}

	if (values.password !== values.password_confirmation) {
		errors.password_confirmation = "As senhas não são iguais.";
	}

	return errors;
};

export const validateFormPagamento = (values) => {
	let formMap = new Map();

	formMap.set('numero_cartao', 'número do cartão');
	formMap.set('nome', 'nome');
	formMap.set('cpf', 'cpf');
	formMap.set('vencimento', 'vencimento');
	formMap.set('cvv', 'cvv');

	let errors = createFormikValidation(formMap, values);

	if (!regexCPF.test(values.cpf) || !validaCpf(values.cpf)) {
		errors.cpf = "Você precisa informar um cpf válido.";
	}

	if (values.vencimento.length === 5) {
		const vencimento = moment(`20${values.vencimento.split("/")[1]}-${values.vencimento.split("/")[0]}`);

		if (!vencimento.isValid()) {
			errors.vencimento = "Data inválida.";
		} else {
			if (moment(vencimento).diff(moment(), 'month') < 0) {
				errors.vencimento = "Data inválida.";
			}
		}
	}

	return errors;
};

export const validateAlterarCartao = (values) => {
	let formMap = new Map();

	formMap.set('numero_cartao', 'número do cartão');
	formMap.set('nome', 'nome');
	formMap.set('cpf', 'cpf');
	formMap.set('vencimento', 'vencimento');
	formMap.set('cvv', 'cvv');

	let errors = createFormikValidation(formMap, values);

	if (!regexCPF.test(values.cpf) || !validaCpf(values.cpf)) {
		errors.cpf = "Você precisa informar um cpf válido.";
	}

	if (values.vencimento.length === 5) {
		const vencimento = moment(`20${values.vencimento.split("/")[1]}-${values.vencimento.split("/")[0]}`);

		if (!vencimento.isValid()) {
			errors.vencimento = "Data inválida.";
		} else {
			if (moment(vencimento).diff(moment(), 'month') < 0) {
				errors.vencimento = "Data inválida.";
			}
		}
	}

	return errors;
};