import React, { Component } from "react";
import { Link } from "react-router-dom";
import BetterBox from "../../../components/BetterBox";
import { TextField } from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import { validateFormPagamento } from "../../auth/AuthUtils";
import { Formik } from "formik";
import InputMask from "react-input-mask";
import { toAbsoluteUrl } from "../../../../_metronic";
import {
  atualizarPlano,
  contratarPlano,
  getFormasPagamento,
} from "../../../crud/pagamento.crud";
import BetterCircularProgress from "../../../components/BetterCircularProgress";
import { formatBRL, pluralMes } from "../../../utils/Utils";
import Swal from "sweetalert2";
import BetterSelect from "../../../components/BetterSelect";
import axios from "axios";
import { Constants } from "../../../utils/Constants";
import * as auth from "../../../store/ducks/auth.duck";
import { connect } from "react-redux";

class PagamentoPage extends Component {
  constructor() {
    super();

    this.state = {
      data: null,
      loading: false,
      submitted: false,
      success: false,
      errors: [],
      plano: null,
      planos: [],
    };
  }

  componentDidMount = () => {
    this.setState({ loading: true });

    getFormasPagamento()
      .catch(() => {
        return Swal.fire(
          "Ops",
          "Ocorreu um erro ao tentar buscar. Por favor, entre em contato com o suporte.",
          "error"
        );
      })
      .then((res) => {
        if (res.status === 200) {
          const planos = res.data;
          const plano = res.data[0];
          this.setState({ planos, plano });
        }
      })
      .finally(() => this.setState({ loading: false }));
  };

  formatarPagamento = (plano) => {
    if (!plano) return;

    return formatBRL(plano?.valor);
  };

  onSubmit = async (values, resetForm, editar_plano = false) => {
    let expDate = values.vencimento.split("/");

    let body = {
      type: "card",
      card: {
        number: values.numero_cartao.replaceAll(" ", "").replaceAll("_", ""),
        holder_name: values.nome,
        exp_month: expDate[0],
        exp_year: expDate[1],
        cvv: values.cvv,
      },
    };

    console.log(body);

    let card = null;

    try {
      this.setState({ submitted: true });

      var instance = axios.create();

      card = await instance.post(
        `${Constants.pagarme_server}/tokens?appId=${Constants.pagarme_public}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
    } catch (e) {
      this.setState({ submitted: false });

      return Swal.fire(
        "Ops!",
        "Ocorreu algum problema ao validar o cartão, verifique os dados e tente novamente",
        "error"
      );
    }

    const data = {
      card_token: card.data?.id,
      cpf: values.cpf,
      parcela: 1,
      plano: this.state.plano?.id,
    };

    if (!editar_plano) {
      contratarPlano(data)
        .then(async (res) => {
          if (res?.data?.errors) {
            return Swal.fire("Ops!", res?.data?.errors[0], "error");
          }

          var payload = { ...this.props.user };
          payload.plano = res.data.plano;

          this.props.fulfillUser(payload);

          resetForm();

          Swal.fire(
            "Sucesso!",
            "Seu pagamento foi aprovado e seu plano já esta ativo.",
            "success"
          );
        })
        .catch(() => {
          Swal.fire(
            "Ops!",
            "Tivemos um problema ao contratar seu plano. Tente novamente.",
            "error"
          );
        })
        .finally(() => {
          this.setState({ submitted: false });
        });
    } else {
      atualizarPlano(data)
        .then(async (res) => {
          if (res?.data?.errors) {
            return Swal.fire("Ops!", res?.data?.errors[0], "error");
          }

          var payload = { ...this.props.user };
          payload.plano = res.data.plano;

          this.props.fulfillUser(payload);

          resetForm();

          this.props.history.push("/meu-plano");

          Swal.fire(
            "Sucesso!",
            "Seu pagamento foi aprovado e seu novo plano já esta ativo.",
            "success"
          );
        })
        .catch(() => {
          Swal.fire(
            "Ops!",
            "Tivemos um problema ao alterar seu plano. Tente novamente.",
            "error"
          );
        })
        .finally(() => {
          this.setState({ submitted: false });
        });
    }
  };

  getInitialValues = () => {
    return {
      numero_cartao: "",
      nome: "",
      cpf: "",
      vencimento: "",
      cvv: "",
      parcelamento: "",
    };
  };

  render() {
    const {
      user: { plano },
    } = this.props;

    return (
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            {!plano && (
              <div
                className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/bg/bg-3.jpg"
                  )})`,
                }}
              >
                <div className="kt-grid__item">
                  <Link
                    to="/"
                    className="kt-login__logo"
                    style={{ display: "inherit" }}
                  >
                    <img
                      alt="Logo"
                      src={toAbsoluteUrl("/images/logo-dark.png")}
                      style={{ maxWidth: "50%", height: "auto" }}
                    />
                  </Link>
                </div>
              </div>
            )}
            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <Formik
                initialValues={this.getInitialValues()}
                validate={(values) => validateFormPagamento(values)}
                onSubmit={(values, { resetForm }) =>
                  this.onSubmit(values, resetForm, plano ? true : false)
                }
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form
                    noValidate={true}
                    autoComplete="off"
                    className="kt-form"
                    onSubmit={handleSubmit}
                  >
                    <BetterCircularProgress loading={this.state.loading}>
                      <>
                        <BetterBox title="Contratar plataforma">
                          <Row>
                            <Col
                              xl={5}
                              className="d-flex justify-content-center"
                            >
                              <div className="container-plano">
                                <p className="tipo-plano">
                                  {this.state.plano?.nome}
                                </p>

                                <p className="text-dark">{`Assinatura com validade de ${pluralMes(
                                  this.state.plano?.meses
                                )}`}</p>

                                <p>
                                  <em>(Renovação automática)</em>
                                </p>

                                <p className="desconto-plano">
                                  {this.state.plano?.desconto &&
                                    `${this.state.plano?.desconto} de desconto`}
                                </p>

                                <p className="preco-plano">
                                  {this.formatarPagamento(this.state.plano)}
                                </p>
                              </div>
                            </Col>

                            <Col xl={7}>
                              <Row>
                                <Col xl={6}>
                                  <InputMask
                                    mask="9999 9999 9999 9999"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.numero_cartao}
                                  >
                                    {() => (
                                      <TextField
                                        label="Número do cartão *"
                                        margin="normal"
                                        name="numero_cartao"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                        value={values.numero_cartao}
                                        helperText={
                                          touched.numero_cartao &&
                                          errors.numero_cartao
                                        }
                                        error={Boolean(
                                          touched.numero_cartao &&
                                            errors.numero_cartao
                                        )}
                                      />
                                    )}
                                  </InputMask>
                                </Col>

                                <Col xl={6}>
                                  <TextField
                                    name="nome"
                                    label="Nome do titular *"
                                    margin="normal"
                                    variant="outlined"
                                    value={values.nome}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={touched.nome && errors.nome}
                                    error={Boolean(touched.nome && errors.nome)}
                                  />
                                </Col>

                                <Col xl={6}>
                                  <InputMask
                                    mask="999.999.999-99"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.cpf}
                                  >
                                    {() => (
                                      <TextField
                                        label="CPF do titular *"
                                        margin="normal"
                                        name="cpf"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                        value={values.cpf}
                                        helperText={touched.cpf && errors.cpf}
                                        error={Boolean(
                                          touched.cpf && errors.cpf
                                        )}
                                      />
                                    )}
                                  </InputMask>
                                </Col>

                                <Col xl={6}>
                                  <InputMask
                                    mask="99/99"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.vencimento}
                                  >
                                    {() => (
                                      <TextField
                                        label="Vencimento *"
                                        margin="normal"
                                        name="vencimento"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                        value={values.vencimento}
                                        helperText={
                                          touched.vencimento &&
                                          errors.vencimento
                                        }
                                        error={Boolean(
                                          touched.vencimento &&
                                            errors.vencimento
                                        )}
                                      />
                                    )}
                                  </InputMask>
                                </Col>

                                <Col xl={6}>
                                  <TextField
                                    name="cvv"
                                    label="CVV *"
                                    margin="normal"
                                    variant="outlined"
                                    value={values.cvv}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    inputProps={{ maxLength: 4 }}
                                    helperText={touched.cvv && errors.cvv}
                                    error={Boolean(touched.cvv && errors.cvv)}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </BetterBox>

                        <BetterBox>
                          <button
                            type="submit"
                            disabled={this.state.submitted}
                            className="btn btn-success btn-bold pull-right"
                          >
                            <i
                              className={`margin-icon ${
                                this.state.submitted
                                  ? "fas fa-sync fa-spin"
                                  : "fa fa-check-square"
                              }`}
                            />
                            {plano ? "Alterar plano" : "Realizar pagamento"}
                          </button>
                        </BetterBox>
                      </>
                    </BetterCircularProgress>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => {
  return {
    user: user,
  };
};

export default connect(mapStateToProps, auth.actions)(PagamentoPage);
