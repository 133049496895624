const url = process.env.REACT_APP_API_URL;
const pagarme_server = process.env.REACT_APP_PAGARME_SERVER_PAYMENT;
const pagarme_public = process.env.REACT_APP_PAGARME_PUBLIC_KEY;

export const Constants = {
	pagarme_server,
	pagarme_public,
	url: url,
	baseUrl: url + "/api/familiar",
};
