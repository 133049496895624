import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const PerfilPage = lazy(() =>
	import("./perfil/PerfilPage")
);

const CriancaPage = lazy(() =>
	import("./crianca/CriancaPage")
);

const AvaliacaoPage = lazy(() =>
	import("./avaliacao/AvaliacaoPage")
);

const PlanoPage = lazy(() =>
	import("./plano/PlanoPage")
);

const RelatorioPage = lazy(() =>
	import("./relatorio/RelatorioPage")
);

const ProfissionalPage = lazy(() =>
	import("./profissional/ProfissionalPage")
);

const UsuarioPage = lazy(() =>
	import("./usuario/UsuarioPage")
);

const MeuPlanoPage = lazy(() =>
	import("./meu-plano/MeuPlanoPage")
);

const PagamentoPage = lazy(() =>
	import("./pagamento/PagamentoPage")
);

const AlterarCartaoPage = lazy(() =>
	import("./pagamento/AlterarCartaoPage")
);

export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/meu-perfil" component={PerfilPage} />
				<Route path="/meu-plano" component={MeuPlanoPage} />
				<Route path="/pagamento" component={PagamentoPage} />
				<Route path="/alterar-cartao" component={AlterarCartaoPage} />
				<Route path="/crianca" component={CriancaPage} />
				<Route path="/avaliacao" component={AvaliacaoPage} />
				<Route path="/plano" component={PlanoPage} />
				<Route path="/relatorio" component={RelatorioPage} />
				<Route path="/profissional" component={ProfissionalPage} />
				<Route path="/usuario" component={UsuarioPage} />

				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense>
	);
}
