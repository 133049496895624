import axios from "axios";
import { Constants } from '../utils/Constants';

export function getFormasPagamento() {
	return axios.get(`${Constants.baseUrl}/plano/list`);
}

export function contratarPlano(form) {
	return axios.post(`${Constants.baseUrl}/plano`, form);
}

export function atualizarPlano(form) {
	return axios.put(`${Constants.baseUrl}/plano`, form);
}

export function cancelarPlano(form) {
	return axios.delete(`${Constants.baseUrl}/plano`, form);
}

export function getDadosPagamento() {
	return axios.get(`${Constants.baseUrl}/plano`);
}

export function updateCartao(form) {
	return axios.put(`${Constants.baseUrl}/plano/cartao`, form);
}